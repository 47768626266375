<template>
    <div class="container service-build">
        <div class="row">
            <div class="width-service">
                <p>{{ $t('serviceBuild.title1')}}</p>
            </div>
            <div class="width-service-img">
                <div class="row">
                    <div class="col-sm-6">
                        <img v-lazy="{src: require('@/assets/S__88662054.webp')}" alt="" class="img-service">
                    </div>
                    <div class="col-sm-6">
                        <img v-lazy="{src: require('@/assets/build1.webp')}" alt="" class="img-service">
                        <img v-lazy="{src: require('@/assets/build2.webp')}" alt="" class="img-service">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="build-blockquote">
        <div class="container">
            <blockquote class="build-bq">{{ $t('serviceBuild.detailBlockq')}}</blockquote>
        </div>
    </div>
    <div class="tud">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 width-tenY">
                    <img v-lazy="{src: require('@/assets/Logo-True-Universal-Design.webp')}" alt="" class="img-tenY">
                </div>
                <div class="col-sm-6 width-tenY">
                    <p class="tenY-title2">{{ $t('serviceBuild.title2')}} <span class="ten-func"><a href="https://blog.assetup.co.th/tud/" class="router-home">{{ $t('serviceBuild.function')}}</a></span></p>
                    <p class="tenY-subtitle">{{ $t('serviceBuild.subtitle1')}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="img-build">
        <div class="container">
            <div class="row">
                <div class="col-sm-6"><img v-lazy="{src: require('@/assets/img_build.webp')}" alt=""></div>
                <div class="col-sm-6"><img v-lazy="{src: require('@/assets/img_build2.webp')}" alt=""></div>
            </div>
            <div class="row row-build" v-for="(item, index) in items" :key="index">
                <div class="column col-build"><img v-lazy="{src: item.img1}" alt="" onclick="openModal();currentSlide(1)" class="hover-shadow cursor"></div>
                <div class="column col-build"><img v-lazy="{src: item.img2}" alt="" onclick="openModal();currentSlide(2)" class="hover-shadow cursor"></div>
                <div class="column col-build"><img v-lazy="{src: item.img3}" alt="" onclick="openModal();currentSlide(3)" class="hover-shadow cursor"></div>
                <div class="column col-build"><img v-lazy="{src: item.img4}" alt="" onclick="openModal();currentSlide(4)" class="hover-shadow cursor"></div>

                <div id="myModal" class="modal">
                    <span class="close cursor" onclick="closeModal()">&times;</span>
                    <div class="modal-content">
                        <div class="mySlides">
                            <div class="numbertext">1 / 4</div>
                            <img v-lazy="{src: item.img1}" alt="" style="width:100%">
                        </div>
                        <div class="mySlides">
                            <div class="numbertext">2 / 4</div>
                            <img v-lazy="{src: item.img2}" alt="" style="width:100%">
                        </div>
                        <div class="mySlides">
                            <div class="numbertext">3 / 4</div>
                            <img v-lazy="{src: item.img3}" alt="" style="width:100%">
                        </div>
                        <div class="mySlides">
                            <div class="numbertext">4 / 4</div>
                            <img v-lazy="{src: item.img4}" alt="" style="width:100%">
                        </div>
                        <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
                        <a class="next" onclick="plusSlides(1)">&#10095;</a>
                    </div>
                </div>
            </div>
            <p class="title-teamwork">{{ $t('serviceBuild.titleTeam')}}</p>
            <div class="content-teamwork">
                <p>{{ $t('serviceBuild.team1')}}</p>
                <p>{{ $t('serviceBuild.team2')}}</p>
                <p>{{ $t('serviceBuild.team3')}}</p>
                <p>{{ $t('serviceBuild.team4')}}</p>
                <p>{{ $t('serviceBuild.team5')}}</p>
                <p>{{ $t('serviceBuild.team6')}}</p>
                <p>{{ $t('serviceBuild.team7')}}</p>
            </div>
        </div>
    </div>
    <div class="priceRate-bg">
        <div class="container">
            <div class="row">
                <div class="col-sm-7 priceRateWidth">
                    <p class="priceRate">{{ $t('serviceBuild.priceRate')}}</p>
                    <p class="priceRate1">{{ $t('serviceBuild.priceRate1')}}</p>

                    <table class="a">
                        <tr>
                            <th>{{ $t('serviceBuild.deposit')}}</th>
                            <th class="pd">{{ $t('serviceBuild.condition')}}</th>
                            <th class="pd">{{ $t('serviceBuild.note')}}</th>
                        </tr>
                        <tr>
                            <td>3,000 {{ $t('serviceBuild.baht')}}</td>
                            <td class="pd">{{ $t('serviceBuild.for150')}}</td>
                            <td class="pd">{{ $t('serviceBuild.note150')}}</td>
                        </tr>
                        <tr>
                            <td>5,000 {{ $t('serviceBuild.baht')}}</td>
                            <td class="pd">{{ $t('serviceBuild.for150more')}}</td>
                            <td class="pd"></td>
                        </tr>
                    </table>

                    <p class="priceRate2">{{ $t('serviceBuild.priceRate2')}}</p>
                    <p class="priceRate3">{{ $t('serviceBuild.priceRate3')}}</p>

                    <p class="priceRateMore">{{ $t('serviceBuild.note')}}</p>
                    <p class="priceRateMore1">{{ $t('serviceBuild.detailnote')}}</p>

                    <p class="priceRateMore">{{ $t('serviceBuild.nohaveboq')}}</p>
                    <p class="priceRateMore1">
                        1. {{ $t('serviceBuild.detailboq11')}}<a href="mailto:info@assetup.co.th?subject=ไฟล์แนบสำหรับถอดรายการวัสดุ" class="router-home" style="color: white;">{{ $t('serviceBuild.detailboq12')}}</a> {{ $t('serviceBuild.detailboq13')}}
                    </p>
                    <p class="priceRate2">2. <router-link to="/service-buddy-two-house" class="router-home" style="color: white;">{{ $t('serviceBuild.detailboq21')}}</router-link> {{ $t('serviceBuild.detailboq22')}}</p>
                </div>
                <div class="col-sm-5 priceRateWidth">
                    <img v-lazy="{src: require('@/assets/S__88662056.webp')}" alt="" class="img-priceRate">
                    <img v-lazy="{src: require('@/assets/buildService/S__44539907_11zon.webp')}" alt="" class="img-priceRate top">
                    <img v-lazy="{src: require('@/assets/buildService/S__44539910_11zon.webp')}" alt="" class="img-priceRate top">
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="work-ex">
            <p class="title-teamwork">{{ $t('serviceBuild.photoClt')}}</p>

            <div class="row" style="margin-top: 5%;">
                <div
                v-for="(src, index) in setone"
                :key="index"
                class="col-sm-3"
                @click="() => showImg1(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible1"
                    :imgs="setone"
                    :index="index"
                    @hide="handleHide1"
                ></vue-easy-lightbox>
            </div>
            <div class="row"  style="margin-top: 3%;">
                <div
                v-for="(src, index) in settwo"
                :key="index"
                class="col-sm-3"
                @click="() => showImg2(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible2"
                    :imgs="settwo"
                    :index="index"
                    @hide="handleHide2"
                ></vue-easy-lightbox>
            </div>
            <div class="row"  style="margin-top: 3%;">
                <div
                v-for="(src, index) in setthree"
                :key="index"
                class="col-sm-3"
                @click="() => showImg3(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible3"
                    :imgs="setthree"
                    :index="index"
                    @hide="handleHide3"
                ></vue-easy-lightbox>
            </div>
            <div class="row"  style="margin-top: 3%;">
                <div
                v-for="(src, index) in setfour"
                :key="index"
                class="col-sm-3"
                @click="() => showImg4(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible4"
                    :imgs="setfour"
                    :index="index"
                    @hide="handleHide4"
                ></vue-easy-lightbox>
            </div>
            <div class="row"  style="margin-top: 3%;">
                <div
                v-for="(src, index) in setfive"
                :key="index"
                class="col-sm-3"
                @click="() => showImg5(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible5"
                    :imgs="setfive"
                    :index="index"
                    @hide="handleHide5"
                ></vue-easy-lightbox>
            </div>
            <div class="row"  style="margin-top: 3%;">
                <div
                v-for="(src, index) in setsix"
                :key="index"
                class="col-sm-3"
                @click="() => showImg6(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible6"
                    :imgs="setsix"
                    :index="index"
                    @hide="handleHide6"
                ></vue-easy-lightbox>
            </div>
            <div class="row"  style="margin-top: 3%;">
                <div
                v-for="(src, index) in setseven"
                :key="index"
                class="col-sm-3"
                @click="() => showImg7(index)"
                >
                <img :src="src" style="width: 100%; margin-top: 5%;" class="glr-build" />
                </div>
                <vue-easy-lightbox
                    :visible="visible7"
                    :imgs="setseven"
                    :index="index"
                    @hide="handleHide7"
                ></vue-easy-lightbox>
            </div>
        </div>
    </div>
    <p class="forCustomer">{{ $t('serviceBuild.forCustomer')}}</p>
    <router-link to="/service-home-success" class="router-home"><p class="pleaseClick">{{ $t('serviceBuild.pleaseClick')}}</p></router-link>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        VueEasyLightbox
    },
    data() {
        return {
            items: [
                { 
                    img1: require('../assets/buildService/build1.webp'),
                    img2: require('../assets/buildService/build2.webp'),
                    img3: require('../assets/buildService/build3.webp'),
                    img4: require('../assets/buildService/build4.webp'),
                },
            ],
            visible1: false,
            visible2: false,
            visible3: false,
            visible4: false,
            visible5: false,
            visible6: false,
            visible7: false,
            index: 0,
            setone: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137157.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137159.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137160.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137161.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137162.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137163.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137164.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137165.jpg',
            ],
            settwo: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137167.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137169.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137170.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137171.jpg',
            ],
            setthree: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137186.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137188.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137189.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137190.jpg',
            ],
            setfour: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137181.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137183.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137184.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137185.jpg',
            ],
            setfive: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137172.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137174.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137175.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137176.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137177.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137178.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137179.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/S__89137180.jpg',
            ],
            setsix: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/360929.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/360934.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/360936.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/360938.jpg',
            ],
            setseven: [
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/AC120F01-48BC-4673-965F-AC0FCB80BBD0-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/6F6C9994-57F7-42D4-8DE4-7F03B6A411B6-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/8C6E843C-06EB-46EF-B79A-0FE7F03AA6E0-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/23C371A5-40BC-4AEC-8FB3-C609EFF8E8D9-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/37BB35B6-907A-4D4E-B616-728F21D4B660-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/94EE66E6-13FF-46B0-92E9-10FDDE9DAEEF-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/171B3578-6FED-479E-82C6-07FC11E6E6D5-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/495E936B-C375-4AEB-AA6D-E6E3CFC2CB0B.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/26440233-CA29-469B-9FDC-AB07699A27C8-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/B073C199-6310-4CC2-BDE5-C0FF9B0E86D2-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/BAD58A0E-6374-4E23-85D8-BC46E52E4AF1-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/E8EDB7D9-08D5-462B-9D03-3AF51CA2CC6C.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/07/IMG_4648.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/146466.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/146467.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/146502.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/350CA349-5B59-440C-9965-12C7F590009C-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/8D190A17-A4BE-4216-B88E-D347E2D82A2B-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/8D8C6ACB-CA0D-44FC-8675-49BE998F090A-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/8D7A37AF-1B3E-42B9-BBF5-9394B2DBA554-scaled.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/7CFE7F13-EAA6-41D6-A367-6D6D939E2C2F.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/377966.jpg',
                'https://blog.assetup.co.th/wp-content/uploads/2021/08/377961.jpg'
            ],
        }
    },
    methods: {
        showImg1(index) {
            this.index = index
            this.visible1 = true
        },
        showImg2(index) {
            this.index = index
            this.visible2 = true
        },
        showImg3(index) {
            this.index = index
            this.visible3 = true
        },
        showImg4(index) {
            this.index = index
            this.visible4 = true
        },
        showImg5(index) {
            this.index = index
            this.visible5 = true
        },
        showImg6(index) {
            this.index = index
            this.visible6 = true
        },
        showImg7(index) {
            this.index = index
            this.visible7 = true
        },
        handleHide1() {
            this.visible1 = false
        },
        handleHide2() {
            this.visible2 = false
        },
        handleHide3() {
            this.visible3 = false
        },
        handleHide4() {
            this.visible4 = false
        },
        handleHide5() {
            this.visible5 = false
        },
        handleHide6() {
            this.visible6 = false
        },
        handleHide7() {
            this.visible7 = false
        },
    }
}
</script>
<style>
.top{
    margin-top: 3%;
}
.glr-build{
    height: auto;
}
@media (min-width: 768px) {
    .glr-build{
        height: 105px;
    }
}
@media (min-width: 992px) {
    .glr-build{
        height: 141px;
    }
}
@media (min-width: 1400px) {
    .glr-build{
        height: 175px;
    }
}
</style>